import React from 'react'
import { Router } from '@reach/router'

import InstantQuote3DPrintingView from '../../views/instant-quote/3d-printing'
import LoginView from '../../views/login'

import PrivateRoute from '../../components/PrivateRoute'

const App = () => {
  return (
    <Router>
      <PrivateRoute
        path="/app/instant-quote/3d-printing"
        component={InstantQuote3DPrintingView}
      />
      <LoginView path="/app/login" />
    </Router>
  )
}

export default App