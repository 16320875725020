import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import { UserContext } from '../context/UserManager'

const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}) => {
  const { user } = useContext(UserContext)
  
  if(!user && location.pathname !== `/app/login`) {
    navigate("/app/login")

    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
