import React, { useContext, useState, useEffect } from 'react'
import { css } from 'styled-components'

import { UserContext } from '../../context/UserManager'

import {
  boxHeights,
  mainOffset,
  breakpoints,
  mediaQueries,
  boxShadows,
  colors,
  space,
  fontSizes,
} from '../../utils/tokens'

import Layout from '../../components/Layout'

import GoogleLogo from '../../assets/icons/google-logo.svg'
import { navigate } from 'gatsby'
import useFirebase from '../../hooks/useFirebase'

const Login = () => {
  const firebase = useFirebase()
  const { setCurrentUser, user } = useContext(UserContext)
  const [isLoginSuccesful, setIsLoginSuccesful] = useState(false)

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase.auth().signInWithPopup(provider)
      })
      .then(result => {
        const { user } = result
        const db = firebase.firestore()

        // check if user already has a profile
        const profilesRef = db.collection('profiles')
          profilesRef
            .where("uid", "==", user.uid)
            .limit(1)
            .get()
            .then((snapshot) => {
              const docId = snapshot.docs[0] && snapshot.docs[0].id
              if (docId) {
                console.log('profile already exists')
              } else {
                profilesRef.doc().set({
                  displayName: user.displayName,
                  email: user.email,
                  uid: user.uid,
                })
              }
              setCurrentUser(result.user)
              setIsLoginSuccesful(true)
            })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (isLoginSuccesful) {
      navigate('/app/instant-quote/3d-printing/')
    }
  }, [isLoginSuccesful])

  useEffect(() => {
    // if user is already set it means that
    // they're a returning user who hasn't logged out
    if (!user) {
      setCurrentUser(user)
    }
  }, [user])

  return (
    <Layout>
        <main
          css={css({
            paddingTop: mainOffset,
            paddingBottom: `${space[4]}px`,
            [mediaQueries.xxl]: {
              height: `calc(100vh - ${boxHeights.banner})`,
              maxWidth: breakpoints.xxxl,
              margin: `0 auto`,
            },
          })}
        >
          <div
            css={css({
              display: `flex`,
              justifyContent: `center`,
              flexDirection: `column`,
              alignItems: `center`,
              padding: `0 ${space[4]}px`,
              [mediaQueries.lg]: {
                width: `480px`,
                margin: `0 auto`,
              },
            })}
          >
            <h1
              css={css({
                fontWeight: `bold`,
                marginBottom: `${space[4]}px`,
              })}
            >
              Signup or Login
            </h1>
            <button
              css={css({
                border: 0,
                width: `100%`,
                cursor: `pointer`,
                boxShadow: boxShadows.card,
                backgroundColor: colors.white,
                borderRadius: `6px`,
                padding: `${space[3]}px ${space[4]}px`,
              })}
              onClick={loginWithGoogle}
            >
              <div
                css={css({
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  fontWeight: `500`,
                })}
              >
                <GoogleLogo
                  css={css({
                    height: fontSizes[3],
                  })}
                />
                <span>Continue with Google</span>
              </div>
            </button>
          </div>
        </main>
    </Layout>
  )
}

export default Login